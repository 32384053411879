import {
    required,
    minLength,
  } from 'vuelidate/lib/validators';
  
  const verifyNumber = (value) => {
    return value === 0 ? false : true;
  }
  
  export default () => {
    return {
      yardInfo: {
        YardId: {required },
        Weight: {  required, verifyNumber },
      },
    }
  }