<template>
  <CCard body-wrapper>
    <CRow>
       <CCol sm="5">
        <CSelect
          size="sm"
          :placeholder="$t('label.select')"
          addLabelClasses="required text-right"
          :label="$t('label.yard')"
          :horizontal="{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          }"
          :options="yards"
          :disabled="isEmpty(yards)"
          :value.sync="$v.yardInfo.YardId.$model"
          :invalid-feedback="errorMessage($v.yardInfo.YardId)"
          :is-valid="hasError($v.yardInfo.YardId)"
        >
        </CSelect>
      </CCol>
      <CCol sm="5">
        <div class="form-group form-row">
          <label class="required text-right col-sm-12 col-lg-5 mt-2">{{$t('label.weight') + '(TON)'}}</label>
          <div class="input-group col-sm-12 col-lg-7">
            <money addLabelClasses="required"
              :label="$t('label.weight')"
              v-model.trim="$v.yardInfo.Weight.$model"
              :invalid-feedback="errorMessage($v.yardInfo.Weight)"
              :is-valid="hasError($v.yardInfo.Weight)"
              v-bind="measure" 
              class="form-control" 
              value="0"
            >
            </money>
          </div>
        </div>
      </CCol>
      <CCol sm="2" class="d-flex justify-content-end align-items-start">
        <CButtonGroup>
          <CButton
            color="add" size="sm" shape="square"
            @click="confirmation"
          >
            <CIcon name="checkAlt" />
          </CButton>
          <CButton 
            class="ml-1" color="wipe" size="sm"  shape="square"
            v-c-tooltip="{
              content: $t('label.clearFields'),
              placement: 'top-end'
            }"
            @click="clearInputs" >
            <CIcon name="cil-brush-alt"/>
          </CButton>
        </CButtonGroup>
      </CCol>
      <CCol sm="5" v-if="edit">
        <div>
          <CSelect
            :value.sync="yardInfo.Status"
            :is-valid="statusSelectColor()"
            :horizontal="{
              label: 'col-sm-12 col-lg-4',
              input: 'col-sm-12 col-lg-8',
            }"
            size="sm"
            :label="$t('label.status')"
            :options="statusOptions"
            addLabelClasses="text-right"
          />
        </div>
      </CCol>
    </CRow>
  </CCard>
</template>
<script>
import YardValidations from "@/_validations/metalscrap/YardFormValidations";
import { VMoney, Money } from "v-money";
import ModalMixin from "@/_mixins/modal";

//DATA
function data() {
  return {
    yardInfo: {
      BillOfLadingYardDetailId: "",
      YardId: "",
      Weight: "",
      Status: 1
    },
    oldStatus: '',
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
  };
}

// METHODS
function isEmpty(arr) {
  return arr.length == 0;
}
function clearInputs(flag = false) {
  let arrKeys = Object.keys(this.yardInfo);
  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "Status":
        this.yardInfo[arrKeys[i]] = 1;
        break;
      default:
        this.yardInfo[arrKeys[i]] = '';
    }
  }
  this.oldStatus = 1;
  this.$v.$reset();
  this.$emit('clear');
}
function submit() {
  try {
    this.$v.yardInfo.$touch();

    if (this.$v.yardInfo.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }
    
    if(!this.edit) {
      let newYard = {
        ...this.yardInfo,
        AuxId: Date.now(),
        Weight: this.yardInfo.Weight,
      }
      delete newYard.BillOfLadingYardDetailId;

      this.$emit("submit", newYard);
    } else {
      this.$emit("update", {
        ...this.yardInfo,
        AuxId: this.item.AuxId,
        Weight: this.yardInfo.Weight,
      });
    }
    this.clearInputs(true);
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function setForm(item) {
  if(!item) return;

  let arrKeys = Object.keys(this.yardInfo);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "Status":
        this.yardInfo.Status = item[arrKeys[i]] == 'ACTIVO' ? 1 : 0;
        this.oldStatus = item[arrKeys[i]] == 'ACTIVO'? 1 : 0;
        break;
      case "Weight":
        if(!this.yardInfo[arrKeys[i]]) {
          this.yardInfo[arrKeys[i]] = item.Weight;
        } else {
          this.yardInfo[arrKeys[i]] = item.WeigthJson[1].Value;
        }
        break;
      default:
        this.yardInfo[arrKeys[i]] = item[arrKeys[i]] ? item[arrKeys[i]] : "";
    }
  }
  this.$v.$touch();
}
function confirmation() {
  if(!this.edit) {
    this.submit();
  } else {
    try {
      this.$v.yardInfo.$touch();
      if (this.$v.yardInfo.$error) {
        throw this.$t('label.errorsPleaseCheck');
      }
      this.statusConfirmation(this.oldStatus, this.yardInfo.Status, this.submit, ``);
    } catch(e) {
      this.notifyError({ text: e });
    }
  }
}
function formatNumericValue(val) {
  return parseFloat(val.replace(/[.]/g, '')?.replace(',', '.')).toFixed(2);
}
function statusSelectColor(){
  return this.yardInfo.Status === 1;
}

//COMPUTED
function currentYard() {
  return this.yardInfo.YardId;
}

export default {
  name: "yard-inputs",
  mixins: [ModalMixin],
  components: {
    Money,
  },
  data,
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    yards: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => null,
    }
  },
  validations: YardValidations,
  directives: {
    
  },
  watch: {
    edit: function (val) {
      if(val)
        this.setForm(this.item);
    },
  },
  methods: {
    isEmpty,
    clearInputs,
    submit,
    setForm,
    confirmation,
    statusSelectColor,
    formatNumericValue,
  },
  computed: {
    currentYard,
  },
};
</script>
<style scoped>

</style>
